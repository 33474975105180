<template>
    <div>
        <div class="header">
            <h1 class="title">{{ $t('navigation.structure') }}</h1>
        </div>
        <div class="search-bar">
            <Input v-model="query" class="margin-right" clearable :placeholder="$t('pages.screen.inputGroupName')" style="width: 140px;" @keyup.native.enter="searchNew" @on-clear="searchNew" ></Input>
            <Button class="float-right" type="default" @click="reset">{{ $t('common.reset') }}</Button>
            <Button class="float-right margin-right" type="primary" @click="searchNew">{{ $t('common.search') }}</Button>
        </div>
        <div class="main-content">
            <Button class="add" type="primary" icon="ios-add" @click="handleAdd(null)">{{ $t('pages.structure.addGroup') }}</Button>
            <Table class="table" row-key="rowid" border :columns="columns" :data="list" :loading="loading">
                <template slot-scope="{ row }" slot="action">
                    <span class="add-sub pointer margin-right" @click="handleAdd(row)">{{ $t('pages.structure.subordinate') }}</span>
                    <span class="edit pointer margin-right" @click="handleEdit(row)">{{ $t('common.edit') }}</span>
                    <span v-if="!row.is_root" class="remove pointer" @click="handleRemove(row.rowid)">{{ $t('common.del') }}</span>
                </template>
            </Table>
        </div>
        <add-modal
            :type="addModalType"
            :sub="addSub"
            :editInfo="editInfo"
            :show="addModalShow"
            @cancel="handleAddModalCancel"
            @confirm="handleAddModalConfirm"
        ></add-modal>
    </div>  
</template>
<script>
    import addModal from './components/addModal'
    import util from '@/libs/util.js'

    export default {
        name: "",
        mixins: [],
        components: {
            addModal
        },
        props: [],
        data () {
            return {
                query: '',
                list: [],
                addModalShow: false,
                addModalType: 'add',
                addSub: false,
                editInfo: {},
                loading: false
            }
        },
        computed: {
            columns() {
                return [
                    {
                        title: this.$t('pages.screen.groupName'),
                        key: 'name',
                        align: 'left',
                        tree: true,
                        minWidth: 190
                    },
                    {
                        title: this.$t('pages.role.note'),
                        key: 'note',
                        align: 'center',
                        minWidth: 120
                    },
                    {
                        title: this.$t('pages.template.sort'),
                        key: 'order_index',
                        align: 'center',
                        minWidth: 100
                    },
                    {
                        title: this.$t('pages.role.creater'),
                        key: 'creator_name',
                        align: 'center',
                        minWidth: 130
                    },
                    {
                        title: this.$t('pages.role.createTime'),
                        key: 'create_time',
                        align: 'center',
                        minWidth: 170
                    },
                    {
                        title: this.$t('common.action'),
                        slot:  'action',
                        align: 'center',
                        minWidth: 120
                    }
                ]
            }
        },
        methods: {
            handleAdd(row) {
                if (row) {
                    this.addSub = true
                    this.editInfo.rowid = row.rowid
                    this.editInfo.name = row.name
                    this.editInfo.parent_dept_id = row.parent_dept_id
                } else {
                    this.addSub = false
                }
                this.addModalShow = true
                this.addModalType = 'add'
            },
            handleEdit(row) {
                this.editInfo = {
                    rowid: row.rowid,
                    name: row.name,
                    note: row.note,
                    order_index: row.order_index,
                    parent_dept_id: row.parent_dept_id
                }
                this.addModalShow = true
                this.addModalType = 'edit'
                this.addSub = false
            },
            handleRemove(rowid) {
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: this.$t('common.sureDel'),
                    onOk: () => {
                        this.$api.delete(`organizations/current/departments/${rowid}`).then(() => {
                            this.$Message.success(this.$t('message.success'))
                            this.handleAddModalConfirm()
                        })
                    }
                })
            },
            handleAddModalCancel() {
                this.addModalShow = false
            },
            reset() {
                this.query = ''
            },
            searchNew() {
                if (this.loading) return
                this.getData()
            },
            handleData(obj) {
                let newObj = obj.constructor === Array ? []:{};
                if (obj.constructor === Object) {
                    obj._showChildren = true
                    obj.create_time = util.handleTime(obj.create_time)
                }
                    if(typeof obj !== 'object'){
                        return
                    }else{
                        for(let i in obj){
                            if(Object.prototype.hasOwnProperty.call(obj, i)){
                                newObj[i] = typeof obj[i] === 'object'?this.handleData(obj[i]):obj[i];
                            }
                        }
                    }
                    return newObj
            },
            getData() {
                let params = {
                    query: this.query
                }
                this.loading = true
                this.$api.get('organizations/current/departments/search', { params }).then(({ data }) => {
                    this.list = this.handleData(data)
                }).finally(() => {
                    this.loading = false
                })
            },
            handleAddModalConfirm() {
                this.$store.dispatch('GET_USER_TREE')
                this.$store.dispatch('GET_UP_TREE')
                this.$store.dispatch('GET_DEPARTMENT_TREE').then(() => {
                    this.getData()
                })
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";
.search-bar {
    padding: 22px 24px 22px 46px;
    background: @background-color;
}
.main-content {
    position: relative;
    padding: 105px 24px 0;
    .add {
        position: absolute;
        top: 22px;
        left: 46px;
    }
    .add-sub, .edit, .remove {
        display: inline-block;
        color: @primary-color;
        &:hover {
            cursor: pointer;
        }
    }
}
</style>